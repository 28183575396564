<template>
  <el-form ref="form" label-width="100px" label-position="left">
    <el-form-item label="默认" prop="content">
      <el-select v-model="value" placeholder="请选择文章类型" :change="chose">
        <el-option
          v-for="item in list"
          :label="item.title"
          :value="item.id"
          :key="item.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="标题">
      <el-input v-model="editObj.title"></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="edit">更新</el-button>

      <el-button type="primary" @click="onSubmit">新增</el-button>
    </el-form-item>

    <Editor :aid="aid"/>
  </el-form>
</template>

<script>
import Editor from "./../../editor.vue";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      aid:0,
      list: [],
      editObj: {
        id: 1,
        title: "",
        insertTime: new Date(),
        content: "",
      },
      value: "",
    };
  },
  created() {
    this.getData();
  },
  beforeDestroy() {
    this.$store.commit("editorContent", "");
  },
  methods: {
    edit() {
      this.editObj.content = this.$store.getters.editorContent;
      let that = this;
      if (this.value === "") {
        this.$notify.error({
          title: "warn",
          message: "未选择以前内容",
        });
        return;
      }
      if (this.editObj.content === "" || this.editObj.title === "") {
        this.$notify.error({
          title: "warn",
          message: "未输入完全！",
        });
        return;
      }
      this.$axios
        .post("/adminApi/editAboutOther", this.editObj)
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.$notify.success({
              title: resp.data.msg,
              message: resp.data.data,
            });
            location.reload();
          }
        });
    },
    onSubmit() {
      this.editObj.content = this.$store.getters.editorContent;
      this.editObj.insertTime = new Date();
      let that = this;
      if (this.value !== "") {
        this.$notify.error({
          title: "warn",
          message: "已选择了以前内容！",
        });
        return;
      }
      if (this.editObj.content === "" || this.editObj.title === "") {
        this.$notify.error({
          title: "warn",
          message: "未输入完全！",
        });
        return;
      }
      this.$axios
        .post("/adminApi/addAboutOther", this.editObj)
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.$notify.success({
              title: resp.data.msg,
              message: resp.data.data,
            });
            location.reload();
          }
        });
    },
    getData() {
      let that = this;
      this.$axios.get("/adminApi/aboutOthers").then(function (resp) {
        that.list = resp.data.data;
      });
    },
    chose(newVal) {
      let that = this;
      this.$axios
        .get("/blogApi/getAbout", {
          params: {
            id: newVal,
          },
        })
        .then(function (resp) {
          that.editObj = resp.data.data;
          that.$store.commit("editorContent", that.editObj.content);
          that.aid=resp.data.data.id;
        });
    },
  },
  watch: {
    value: function (val) {
      this.chose(val);
    },
  },
};
</script>

<style>
</style>